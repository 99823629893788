import {
    Tools,
    UrlService,
    Cookie,
    ExternalApiService,
    PlatformAPI,
    SolarAPI,
    TranslationsAPI,
    Dictionary,
    SolarGatewayAPI,
    LocalstorageTTLService,
} from '../js/services';
import { AsgFW } from '../js/asg-fw';
import { AsgChat } from '../components/chat/fresh-chat';

export const exposeCoreScripts = () => {
    // services.js
    window.Tools = Tools;
    window.UrlService = UrlService;
    window.Cookie = Cookie;
    window.ExternalApiService = ExternalApiService;
    window.PlatformAPI = PlatformAPI;
    window.SolarAPI = SolarAPI;
    window.TranslationsAPI = TranslationsAPI;
    window.Dictionary = Dictionary;
    window.SolarGatewayAPI = SolarGatewayAPI;
    window.LocalstorageTTLService = LocalstorageTTLService;

    // asg-fw.js
    window.AsgFW = AsgFW;

    // fresh-chat.js
    window.AsgChat = AsgChat;
};
