/* eslint-disable no-underscore-dangle */

// eslint-disable-next-line no-unused-vars
var AsgChat = (function () {
    let configObj = {};
    let initializationConfig = {};
    let preChatTemplate = {};
    let pamPlayerRisksDetails = {};
    let openOnLoad = false;
    const preChatScript = 'https://snippets.freshchat.com/js/fc-pre-chat-form-v2.js';
    const widgetScript = 'https://wchat.freshchat.com/js/widget.js';
    const customCallbacks = {};
    let brandDomain;
    let allowedCustomUserProperties;
    const userCreatedEventName = 'AsgFreshChatUserCreated';
    let restoreId;

    /**
     * First entry-point, prepare data and initiate chat (PreChat or FreshChat)
     * @param data
     */
    const initChat = function (data) {
        document.dispatchEvent(new Event('ChatIniting'));
        brandDomain = data.brandDomain;
        allowedCustomUserProperties = data.customUserProperties || [];

        if (Object.prototype.hasOwnProperty.call(data, 'openOnLoad')) {
            openOnLoad = data.openOnLoad;
        }

        _checkIsChatShouldBeOpened();
        _setDefaultConfigObj(data);
        _setPreChatTemplate(data);

        // different load strategies should be used for logged in player
        // global config should be setup before fresh chat scripts will load
        // in other case - restoreId will not be set from FC side
        if (AsgFW.isLoggedIn() || AsgFW.isLoggedInByCookie()) {
            _presetGlobalChatSettings();
            _waitForSession()
                .then(_getRestoreId)
                .then(_callPamPlayerRisksDetails)
                .then(() => {
                    _setLoggedInConfigObj();
                    return _loadWidgetScript();
                })
                .then(() => {
                    _initFullChat();
                    _proxyNgEvents();
                    _setCloseSurveyWhenCompleted();
                })
                .catch(err => console.error(err));
        } else {
            _setGlobalChatSettings();
            // for logged out players - just load scrips and subscribe to events
            _loadPreChatScript()
                .then(_loadWidgetScript)
                .then(() => {
                    _proxyNgEvents();
                    _setCloseSurveyWhenCompleted();
                })
                .catch(err => console.error(err));
        }
    };

    /**
     * Loads pre chat script async
     * @returns {Promise}
     * @private
     */
    const _loadPreChatScript = function () {
        return new Promise((resolve) => {
            Tools.getScript(preChatScript, resolve);
        });
    };

    /**
     * Loads widget script async
     * @returns {Promise}
     * @private
     */
    const _loadWidgetScript = function () {
        return new Promise((resolve) => {
            Tools.getScript(widgetScript, resolve);
        });
    };

    /**
     * Retrieves restoreId from cookie
     * Subscribes to user created event in order to save restoreId for new chats
     * @private
     */
    const _getRestoreId = async function () {
        const freshChatData = await AsgFW.getHostData(AsgFW.fwHostDataIDs.FRESH_CHAT);

        if (freshChatData && freshChatData.restoreId) {
            restoreId = freshChatData.restoreId;
        } else {
            document.addEventListener(userCreatedEventName, async () => {
                const currentFreshChatData = await AsgFW.getHostData(
                    AsgFW.fwHostDataIDs.FRESH_CHAT,
                );

                let finalFwDataToSave = { restoreId };
                if (currentFreshChatData !== null) {
                    // update only restoreId
                    finalFwDataToSave = { ...currentFreshChatData, restoreId };
                }

                await AsgFW.setHostData(finalFwDataToSave, AsgFW.fwHostDataIDs.FRESH_CHAT);
            });
        }
    };

    /**
     * For logged in players - subscribes to user:created callback in order to get restoreId
     * Checks if chat should be opened after page loads.
     *
     * Called both for logged in and logged out users.
     * @private
     */
    const _onInit = function () {
        if (AsgFW.isLoggedIn() || AsgFW.isLoggedInByCookie()) {
            _setUserCreatedCallback();
        } else {
            _initPreChat();
        }
        // keep chat opened on refresh if user didn't close it manually
        _setChatOpenedFlag();
        _applyCustomCallbacks();
        document.dispatchEvent(new Event('ChatReady'));
        Tools.log('FreshChat is ready', configObj);
    };

    /**
     * When logging in, prechat is destroyed and fresh chat is initialized in full mode
     * @private
     */
    const _transitionToFreshChat = function () {
        _closePreChat();
        _destroyFreshChat()
            .then(_getRestoreId)
            .then(_callPamPlayerRisksDetails)
            .then(() => {
                _setLoggedInConfigObj();
                _setCloseSurveyWhenCompleted();
                _initFullChat();
                _setUserCreatedCallback();
                _trackFreshChatEvent('Player Logged In');
            });
    };

    /**
     * When logging out, fresh chat is destroyed and prechat is initialized
     * @private
     */
    const _transitionToPreChat = function () {
        _trackFreshChatEvent('Player Logged Out');

        window.fcWidget.close();
        _clearSession()
            .then(_destroyFreshChat)
            .then(() => {
                _clearPamPlayerRisksDetails();
                _setDefaultConfigObj(initializationConfig);
                _setPreChatTemplate(initializationConfig);
                _setGlobalChatSettings();
                _setCloseSurveyWhenCompleted();
                // init with default config, required for pre chat work properly
                _initFullChat();
                _initPreChat();
            })
            .catch(() => { /* according to spec - catch() has callback as required param */ });
    };

    /**
     * Subscribe to chat opening event from NG widgets
     * Subscribe log in\out events from NG widgets
     * @private
     */
    const _proxyNgEvents = function () {
        const onLoginChatHandler = function () {
            _transitionToFreshChat();
        };
        const onLogoutChatHandler = function () {
            _transitionToPreChat();
        };

        document.addEventListener('fw_LoggedIn', onLoginChatHandler);
        document.addEventListener('fw_LoggedOut', onLogoutChatHandler);
        document.addEventListener('fw_depositSucceeded', () => {
            _trackFreshChatEvent('Deposit Status', { value: 'Success' });
        });
        document.addEventListener('fw_depositAborted', () => {
            _trackFreshChatEvent('Deposit Status', { value: 'Unsuccess' });
        });
    };

    /**
     * Return which shift to handle on Malta day or night
     * @returns {string} day or night
     */
    const getDayNight = function () {
        let dayNight = 'night'; // init return

        // get Malta date time
        const d = new Date(new Date().toLocaleString('en-US', {
            timeZone: 'Europe/Malta',
        }));

        let hours = d.getHours();// get the hours

        if (hours === 0) { // if midnight then change hours to 24
            hours = 24;
        }

        // check day night
        if (hours >= 8 && hours <= 23) {
            dayNight = 'day';
        }

        return dayNight;
    };

    /**
     * Validates if 'public' tag should be added.
     * @private
     */
    const _isPublicTagRequired = function () {
        const disabledCountries = ['gb', 'uk', 'nz', 'ca'];
        let detectedCountry;

        if (AsgFW.isLoggedIn()) {
            detectedCountry = AsgFW.getPlayerCountryCode().toLowerCase();
        } else {
            detectedCountry = AsgFW.getDetectedCountry();
        }
        return !disabledCountries.includes(detectedCountry);
    };

    const _generateTags = function (data) {
        const fcTags = [
            AsgFW.getRegulation(),
            data.brandName,
            getDayNight(),
        ];

        // Add country specific tag (like 'country_UK').
        const countryTag = _getCountryTag();
        if (countryTag) {
            fcTags.push(countryTag);
        }

        // Add 'public' tag.
        if (_isPublicTagRequired()) {
            fcTags.push('public');
        }

        // Add vip or predicted vip level.
        if (AsgFW.isLoggedIn()) {
            fcTags.push(`vip_${AsgFW.getSessionDetails().PlayerVipLevel}`);
        } else if (data.predictedVIP) {
            fcTags.push(`pvip_${data.predictedVIP}`);
        }

        return fcTags;
    };

    /**
     * Set default config to Freshchat including logged out user data.
     * @param data
     * @private
     */
    const _setDefaultConfigObj = function (data) {
        initializationConfig = data;
        const customUserProperties = _filterOutCustomUserProperties({
            predictedVIP: data.predictedVIP,
            vipLevel: '-',
            registrationDate: '-',
            language: '-',
            countryCode: '-',
            regulationType: '-',
            sessionToken: '-',
            loginStatus: 'Logged Out',
            lastLoginTime: '-',
            ChatLanguage: data.locale,
        });

        configObj = {
            token: data.token,
            host: data.host,
            open: openOnLoad,
            siteId: data.brandName,
            locale: data.locale,
            tags: _generateTags(data),
            config: {
                headerProperty: {
                    hideChatButton: true,
                    // If you have multiple sites you can use the appName
                    // and appLogo to overwrite the values.
                    appName: data.brandName,
                    backgroundColor: data.bgc,
                    foregroundColor: data.fgc,
                },
                cssNames: {
                    // The below element is mandatory.
                    widget: 'custom_fc_frame',
                    // The below element is mandatory.
                    expanded: 'custom_fc_expanded',
                },
                content: {
                    headers: {
                        csat_question: data.uiTexts && data.uiTexts.csatQuestion,
                    },
                },
            },
            meta: customUserProperties,
            onInit: _onInit,
        };
    };

    /**
     * Returns tag for players country. For logged in - gets country from NG
     * For logged out - detects country by IP.
     * IN case of error - returns null
     * @returns {string|null}
     * @private
     */
    const _getCountryTag = function () {
        const isLoggedIn = AsgFW.isLoggedIn();

        if (isLoggedIn) {
            return `country_${AsgFW.getPlayerCountryCode().toUpperCase()}`;
        }

        const countryByIp = AsgFW.getDetectedCountry();
        if (countryByIp) {
            return `country_${countryByIp.toUpperCase()}`;
        }

        return null;
    };

    /**
     * Installs fields for pre chat window
     * @param data
     * @private
     */
    const _setPreChatTemplate = function (data) {
        preChatTemplate = {
            // Form header color and Submit button color.
            mainbgColor: data.bgc || '#0aa4db',
            // Form Header Text and Submit button text color.
            maintxColor: data.fgc || '#fff',
            // Chat Form Title
            heading: data.preChatTexts.preChatHeaderText,
            // Chat form Welcome Message
            textBanner: data.preChatTexts.preChatBannerText,
            // Submit Button Label.
            SubmitLabel: data.preChatTexts.preChatStartChat,
            // Fields List - Maximum is 5
            // All the values are mandatory and the script will not work if not available.
            fields: {
                field1: {
                    // Type for Name - Do not Change
                    type: 'name',
                    // Label for Field Name, can be in any language
                    label: data.preChatTexts.preChatName,
                    // Default - Field ID for Name - Do Not Change
                    fieldId: 'name',
                    // Required "yes" or "no"
                    required: 'yes',
                    // Error text to be displayed
                    error: data.preChatTexts.preChatNameError,

                },
                field2: {
                    // Type for Email - Do Not Change
                    type: 'email',
                    // Label for Field Email, can be in any language
                    label: data.preChatTexts.preChatEmail,
                    // Default - Field ID for Email - Do Not Change
                    fieldId: 'email',
                    // Required "yes" or "no"
                    required: 'yes',
                    // Error text to be displayed
                    error: data.preChatTexts.preChatEmailError,
                },
            },
        };
    };

    /**
     * Sets custom callbacks to be assigned on chat init.
     *
     * Subscribe to "ChatIniting" event to this call method in time.
     * Callbacks related to same events will be overrided.
     * @param newCustomCallbacks
     */
    const setCustomCallbacks = newCustomCallbacks => {
        Object.assign(customCallbacks, newCustomCallbacks);
    };

    /**
     * Walks throught customCallbacks object and apply custom callbacks.
     *
     * Object should contains event-callback pairs, e.g., {'widget:opened': window.myCallback}
     * @private
     */
    const _applyCustomCallbacks = () => {
        const customCallbacksExists = customCallbacks
            && typeof customCallbacks === 'object'
            && Object.keys(customCallbacks).length > 0;

        if (!customCallbacksExists) {
            return;
        }

        Object.entries(customCallbacks).forEach(eventCbPair => {
            const [event, callback] = eventCbPair;

            window.fcWidget.on(event, callback);
        });
    };

    /**
     * Adds user properties to global Fresh chat config
     * Logged in users configObj is extended with user properties
     * @private
     */
    const _setLoggedInConfigObj = function () {
        const playerData = AsgFW.getSessionDetails();
        let customUserProperties = _filterOutCustomUserProperties({
            predictedVIP: playerData.PlayerVipLevel, // player's VIP level
            vipLevel: playerData.PlayerVipLevel, // player's VIP level
            registrationDate: playerData.PlayerRegistrationDate,
            language: playerData.PlayerLanguage,
            countryCode: playerData.CountryCode,
            regulationType: playerData.PlayerRegulationType,
            sessionToken: playerData.SessionToken,
            loginStatus: 'Logged In',
            lastLoginTime: (new Date(playerData.LastLoginTimeStamp)).toUTCString(),
            ChatLanguage: initializationConfig.locale,
        });

        customUserProperties = _addPamPlayerRisksDetails(customUserProperties);

        const propertiesObj = {
            tags: _generateTags(initializationConfig),
            externalId: playerData.PlayerID, // user’s id unique to your system
            restoreId: restoreId || null, // id to restore conversation
            firstName: playerData.PlayerName, // user’s first name
            lastName: playerData.PlayerLastName, // user’s last name
            email: playerData.PlayerEmail, // user’s email address
            phone: playerData.PlayerCellphone, // phone number without country code
            phoneCountryCode: '+', // phone’s country code
            meta: customUserProperties,
        };

        // configObj = Object.assign(configObj,propertiesObj);
        // fix for IE11
        Object.assign(configObj, propertiesObj);
    };

    /**
     * Presets settings that have to exist before chat will initiated
     * and wouldn't work if passed inside object on init.
     * @private
     */
    const _presetGlobalChatSettings = function () {
        window.fcSettings = {
            onInit: _onInit,
        };
    };

    /**
     * Sets internal config into window.fcSettings that used by PreChat
     * @private
     */
    const _setGlobalChatSettings = function () {
        window.fcSettings = configObj;
    };

    const _filterOutCustomUserProperties = customUserProperties => {
        const filteredCustomUserProperties = {};

        Object.keys(customUserProperties).forEach(propertyName => {
            if (allowedCustomUserProperties.includes(propertyName)) {
                filteredCustomUserProperties[propertyName] = customUserProperties[propertyName];
            }
        });

        return filteredCustomUserProperties;
    };

    /**
     * Waits for session if logged in by cookie only.
     * @returns {Promise}
     * @private
     */
    const _waitForSession = () => new Promise(resolve => {
        if (AsgFW.isLoggedInByCookie() && !AsgFW.isLoggedIn()) {
            document.addEventListener('fw_asgReady', resolve, { once: true });
        } else {
            resolve();
        }
    });

    /**
     * Calls NG framework's method to set Player risk details.
     * @returns {Promise}
     * @private
     */
    const _callPamPlayerRisksDetails = () => new Promise(resolve => {
        ng_fw.getPlayerRisksDetails(data => _setPamPlayerRisksDetails(data, resolve));
    });

    /**
     * Set Player risk details got from NG framework.
     * Use as ng_fw.getPlayerRisksDetails callback.
     * @param data
     * @param resolve Parent's Promise resolve() method
     * @private
     */
    const _setPamPlayerRisksDetails = (data, resolve) => {
        const paramsMapping = {
            PlayerID: 'PlayerID',
            FS: 'FraudSuspect',
            BS: 'BonusSeeker',
            DaysSinceLastDeposit: 'DaysSinceLastDeposit',
            RGIS: 'RGInteractionStatus',
            RGS: 'RGScore',
            AMS: 'AMLScore',
            AMSS: 'AMLSowStatus',
            AMCS: 'AMLCddStatus',
            OtherDocsRequired: 'OtherDocsRequired',
        };
        if (data && typeof data === 'object' && Object.keys(data).length > 0) {
            Object.keys(paramsMapping).forEach(pamParam => {
                const chatParam = paramsMapping[pamParam];
                if (allowedCustomUserProperties.includes(chatParam)) {
                    pamPlayerRisksDetails[chatParam] = Object.hasOwn(data, pamParam)
                        ? _maybeConvertPamPlayerDataType(pamParam, data[pamParam])
                        : null;
                }
            });
        }
        resolve();
    };

    const _maybeConvertPamPlayerDataType = (param, data) => {
        const convertingConfigs = {
            OtherDocsRequired: 'arrayToString',
        };

        if (!Object.prototype.hasOwnProperty.call(convertingConfigs, param)) {
            return data;
        }

        let convertedData = data;

        if (convertingConfigs[param] === 'arrayToString') {
            convertedData = data.join(', ');
        }

        return convertedData;
    };

    /**
     * Add Player risk details.
     * @param customUserProperties
     * @returns {*}
     * @private
     */
    const _addPamPlayerRisksDetails = (customUserProperties) => {
        if (Object.keys(pamPlayerRisksDetails).length > 0) {
            Object.assign(customUserProperties, pamPlayerRisksDetails);
        }
        return customUserProperties;
    };

    const _clearPamPlayerRisksDetails = () => {
        pamPlayerRisksDetails = {};
    };

    /**
     * Calls fcPreChatform.fcWidgetInit()
     * @private
     */
    const _initPreChat = () => {
        window.fcPreChatform.fcWidgetInit(preChatTemplate);
    };

    /**
     * Calls fcWidget.init()
     * @private
     */
    const _initFullChat = () => {
        window.fcWidget.init(configObj);
        Tools.log('FreshChat has been initialized ', configObj);
    };

    /**
     * Closes prechat form
     * Calls external API from freshchat - on_fc_pre_form_close()
     * @private
     */
    const _closePreChat = function () {
        // close pre chat before init full chat mode.
        try {
            // External function on_fc_pre_form_close() may cause JS error, can't control it
            if (window.fcPreChatform && window.fcPreChatform.on_fc_pre_form_close) {
                window.fcPreChatform.on_fc_pre_form_close();
            }
        } catch (e) {
            // error means pre chat was not opened - do nothing
        }
    };

    /**
     * Destroys fresh chat widget
     * @return {Promise}
     * @private
     */
    const _destroyFreshChat = () => (new Promise(resolve => {
        window.fcWidget.on('widget:destroyed', function () {
            resolve();
        });
        window.fcWidget.destroy();
    }));

    /**
     * Clears users session
     * @returns {Promise}
     * @private
     */
    const _clearSession = function () {
        return window.fcWidget.user.clear();
    };

    /**
     * Subscribes to Fresh chat events and saves state of chat open/closed
     * @private
     */
    const _setChatOpenedFlag = function () {
        window.fcWidget.on('widget:opened', function () {
            Cookie.create('chatwidgetopen', '1', '180', '/', `.${brandDomain}`);
        });
        window.fcWidget.on('widget:closed', function () {
            Cookie.remove('chatwidgetopen', '/', `.${brandDomain}`);
        });
    };

    /**
     * Subscribes to Fresh chat user created event to get Restore ID
     * @private
     */
    const _setUserCreatedCallback = function () {
        window.fcWidget.on('user:created', function (resp) {
            const status = resp && resp.status;
            const data = resp && resp.data;

            if (status === 200) {
                if (!!data && data.restoreId) {
                    restoreId = data.restoreId;
                    document.dispatchEvent(new Event(userCreatedEventName));
                }
            }
        });
    };

    /**
     * Closes FC widget after csat survey has been completed
     * @private
     */
    const _setCloseSurveyWhenCompleted = function () {
        window.fcWidget.on('csat:updated', () => {
            window.fcWidget.close();
        });
    };

    /**
     * Calls after page load/refresh and opens chat if it was not closed before page refresh
     * @private
     */
    const _checkIsChatShouldBeOpened = function () {
        const chatFlag = Cookie.read('chatwidgetopen');

        if (chatFlag) {
            openOnLoad = true;
        }
    };

    /**
     * Sends event to Freshchat. It's appearing in admin panel for operators
     * Sends additional data with event:
     *  - date in GTM
     * @private
     */
    const _trackFreshChatEvent = function (eventName, props = {}) {
        window.fcWidget.track(eventName, { ...props, time: (new Date()).toUTCString() });
    };

    return {
        initChat,
        setCustomCallbacks,
    };
})();

/*** EXPORTS FROM exports-loader ***/
export {
  AsgChat
};
